.policy-checkbox {
  position: relative;

  input {
    position: absolute;
    width: 18px;
    height: 18px;

    &:checked + label {
      &:before {
        background-image: url("../images/svg/checkbox-checked.svg");
      }
    }
  }

  label {
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: rgba(0, 0, 0, .4);
    padding-left: 32px;

    &:before {
      content: "";
      position: absolute;
      top: 5px;
      left: 0;
      width: 18px;
      height: 18px;
      background-image: url("../images/svg/checkbox-unchecked.svg");
      transition: background 0.2s;
    }
  }

  &__policy-link {
    color: rgba(0, 0, 0, .4);
    width: max-content;
    border-bottom: 1px solid rgba(0, 0, 0, .4);
  }
}

@include respond-up('s-large') {
  .policy-checkbox {
    :hover {
      label {
        cursor: pointer;

        &:before {
          background-image: url("../images/svg/checkbox-hover.svg");
        }
      }
    }

    label{
      max-height: 12px;
    }
  }
}

@include respond-up('medium') {
  .policy-checkbox {

    &:nth-child(2n +1){
      margin-top: 12px;
    }


    label {
      font-size: 14px;
      padding-bottom: 0;
    }
  }
}

@include respond-down('small') {
  .policy-checkbox {

    &:nth-child(2n +1){
      margin-top: 10px;
    }


    label {
      font-size: 12px;
      padding-bottom: 0;
    }
  }
}